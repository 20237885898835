<template>
  <el-dialog
    :title="$t('device.siteAssignment')"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
    <el-alert
      :title="$t('device.siteAssignmentTips')"
      type="info"
      center
      :closable="false"
    ></el-alert>
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item :label="$t('device.site')" prop="site">
        <select-site v-model="form.site" defaultShow></select-site>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { SelectSite } from '@/components'
import { deviceSiteAssign } from '@/api/device'

export default {
  name: 'dialog-upgrade-firmware',
  components: {
    SelectSite,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    req: Object,
  },
  data() {
    return {
      form: {
        site: '',
      },
      rules: {
        site: {
          required: true,
          message: this.$t('sipServer.siteIdRule')
        },
      },
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            deviceIds: this.req.selectList,
            site: this.form.site,
          }
          deviceSiteAssign(params)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$emit('update:visible', false)
              this.$emit('change')
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 55px 0;
}
.form {
  padding: 43px 88px;
}
</style>
