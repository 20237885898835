<template>
  <el-dialog
    :title="$t('device.upgradeFirmware')"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item :label="$t('device.firmwareVersion')" prop="firmwareId">
        <SelectTree
          v-model="form.firmwareId"
          :data="selectTreeData"
          :props="selectTreeProps"
          default-expand-all
          highlight-current
          :placeholder="$t('components.selectFirmware.selectFirmwarePla')"
          style="width: 100%;"
        />
      </el-form-item>
      <el-form-item :label="$t('task.executionModel')" prop="type">
        <el-radio v-model="form.type" :label="1">{{ $t('task.immediate') }}</el-radio>
        <el-radio v-model="form.type" :label="2">{{ $t('task.scheduled') }}</el-radio>
      </el-form-item>
      <el-form-item :label="$t('task.taskName')" prop="name" v-if="form.type == 2">
        <el-input
          v-model="form.name"
          :maxlength="128"
          :placeholder="$t('task.taskNamePla')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('task.taskTime')" prop="time" v-if="form.type == 2">
        <el-date-picker
          v-model="form.time"
          type="datetime"
          value-format="timestamp"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { firmwareUpgrade, selectUpgradeVersionList } from '@/api/firmware'
import { SelectTree } from '@/components'
import { pushSuccessMassage } from '@/plugins/methods'

export default {
  name: 'dialog-upgrade-firmware',
  components: { SelectTree },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    req: Object,
  },
  data() {
    const now = new Date(new Date().toLocaleDateString()).getTime()
    return {
      form: {
        firmwareId: '',
        type: 1,
        name: '',
        time: now,
      },
      rules: {
        firmwareId: {
          required: true,
          message: this.$t('device.firmwareVersionRule'),
          trigger: 'change',
        },
        type: {
          required: true,
        },
        name: {
          required: true,
          message: this.$t('task.taskNameRule'),
          trigger: 'blur',
        },
        time: {
          required: true,
          validator: (rule, value, callback) => {
            const nowTime = parseInt(new Date().getTime()/1000)*1000
            let legalTime = new Date().getTime() + (5 * 60 * 1000)
            if (nowTime == value) {
              this.form.time = value + (10 * 60 * 1000)
            } else if (value - legalTime < 0) {
              callback(new Error(this.$t('task.taskBeginTimeRule')))
            } else {
              callback()
            }
          },
          trigger: 'change',
        },
      },
      selectTreeData: [],
      selectTreeProps: {
        value: 'id',
        children: 'child',
        label: 'firmwareVersion',
        subLabel: 'firmwareName'
      },
    }
  },
  mounted() {
    this.getFirmware()
  },
  methods: {
    getFirmware() {
      let sites = new Set(this.req.selectList.map(item => item.site))
      selectUpgradeVersionList({
        site: [...sites],
        supportModel: this.req.selectList.map(e => e.deviceModel)
      }).then(({ data }) => {
        this.selectTreeData = data.result.rows
      })
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            firmwareId: this.form.firmwareId,
            deviceIds: this.req.selectList.map(e => e.id),
            type: this.form.type,
            name: this.form.name,
            beginTime: this.form.time,
            endTime: this.form.time + 86399000,
          }
          // console.log(params);
          firmwareUpgrade(params)
            .then(() => {
              pushSuccessMassage(this)
              this.$emit('update:visible', false)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
