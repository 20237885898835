<template>
  <div class="template-root">
    <div v-if="accountList && accountList.length>0">
      <el-popover
          class="list"
          placement="right"
          width="600"
          trigger="click">
        <table-pagination :table-data="accountList" :column-data="columnData" :paginationHide="true"/>
        <div class="warning text-warning" v-show="deviceStatus!='1'">
          {{
            deviceStatus == '2'
                ? $t('device.accountStatusAbnormalTip')
                : $t('device.accountStatusOfflineTip')
          }}
        </div>
        <el-button slot="reference">
          <el-row>
            <el-col v-for="(item,i) in table" v-bind:key="i">
              <div class="line">
                <span :class="item.status===1?'normal' : 'abnormal'" class="line-left">L{{ item.no }}</span>
                <span class="line-right">{{ table[i].phoneNumber }}</span>
              </div>
            </el-col>
          </el-row>
        </el-button>
      </el-popover>
      <div class="button">
        <el-button v-show="showMore" icon="el-icon-caret-bottom" @click="more"/>
        <el-button v-show="showLess" icon="el-icon-caret-top" @click="less"/>
      </div>
    </div>
    <div v-else>{{ $t('dist.deviceAccountStatus.noAccount') }}</div>

  </div>
</template>

<script>
import TablePagination from "@/components/table-pagination";

export default {
  name: "DeviceStatus",
  components: {TablePagination},
  props: {
    accountList: {
      type: Array,
      default: () => []
    },
    refreshSwitcher: Boolean,
    deviceStatus: Number,
    defaultValue:{
      default: false
    }
  },
  data() {
    return {
      defaultShowLineNum: 3,
      table: null,
      showMore: false,
      showLess: false,
      columnData: [
        {
          label: this.$t('device.account'),
          prop: 'noDisplay',
          minWidth: 100,
        },
        {
          label: this.$t('sipAccount.userName'),
          prop: 'phoneNumber',
          minWidth: 100,
        },
        {
          label: this.$t('sipAccount.sipServer'),
          prop: 'sipServer',
          minWidth: 150,
        },
        {
          label: this.$t('device.accountStatus'),
          prop: 'statusDisplay',
          minWidth: 150,
          placeholderDisable: true,
        },
      ]
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.showMore = this.defaultValue;
      this.showLess = this.defaultValue;

      this.addProperties();

      if (this.accountList) {
        this.table = this.accountList.slice(0, this.defaultShowLineNum);
      }
      if (this.accountList && this.accountList.length > this.defaultShowLineNum && this.table && this.table.length > 0) {
        this.showMore = true;
      }
    },
    more() {
      this.table = this.accountList
      this.showMore = false;
      this.showLess = true;
    },
    less() {
      this.table = this.accountList.slice(0, this.defaultShowLineNum);
      if (this.accountList.length > this.defaultShowLineNum) {
        this.showMore = true;
      }
      this.showLess = false;
    },
    addProperties() {
      if (this.accountList) {
        for (let i = 0; i < this.accountList.length; i++) {
          let line = this.accountList[i];
          line.noDisplay = this.$t('device.account') + line.no;
          line.statusDisplay = line.status == "1" ? this.$t('dist.deviceAccountStatus.normal') : this.$t('dist.deviceAccountStatus.abnormal');
        }
      }
    },
  },
  watch: {
    refreshSwitcher() {
      this.initData();
    }
  }
}
</script>

<style lang="scss" scoped>
.template-root {
  .list {
    padding: 0 0 0 0;

    .el-button {
      padding: 0 0 0 0;
      border-width: 0px;

      .line {
        width: 9.5rem;
        display: flex;
        padding: 5px 5px;

        .line-left {
          color: white;
          min-width: 40px;
          border-radius: 8px;
          margin-right: 1rem;
        }

        .line-right {
          overflow-x: hidden;
        }
      }
    }


  }

  .button {
    .el-button {
      padding: 0 0 0 0;
      border-width: 0;
      margin-left: 40%;
      width: 25px;
      height: 25px;
    }
  }
}


.normal {
  background-color: #7DD97C;
}

.abnormal {
  background-color: #929292;
}

.warning {
  text-align: center;
}

</style>